import React from 'react'
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import OurSolutions from "../components/Index/OurSolutions"
import OurServices from "../components/Index/OurServices"
import OurFeatures from "../components/Index/OurFeatures"
import TeamMember from "../components/Index/TeamMember"
import RecentProjects from "../components/Index/RecentProjects"
import Pricing from "../components/Index/Pricing"
import Testimonials from "../components/Index/Testimonials"
import Partner from "../components/Index/Partner"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import OurBlog from "../components/Index/OurBlog"
import Footer from "../components/App/Footer"


 
const Details = ({ data }) => {
    const { title, content } = data.project
    return (
        <Layout>
            <Navbar />

            <PageBanner
                pageTitle={title}
                homePageText="Inicio" 
                homePageUrl="/" 
                activePageText={title}
            />

            <section className="case-studies-details-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="case-studies-details-desc">
                                <ReactMarkdown source={content} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


      

            <ProjectStartArea />
            <Footer />
    
        </Layout>
    );
}

export const query = graphql`
    query GetSingleProjects($slug: String) {
        project: strapiProjects(slug: { eq: $slug }) {
            title
            content
        }
    }
`

export default Details